import { HDR_SERVICES_TYPE } from "hdr-process-data"

export const getHDRServiceAcronym = (type: HDR_SERVICES_TYPE) => {
  switch (type) {
    case HDR_SERVICES_TYPE.health:
      return "HEALTH"
    case HDR_SERVICES_TYPE.temp:
      return "TEMP"
    case HDR_SERVICES_TYPE.tempMMM:
      return "TEMP_MMM"
    case HDR_SERVICES_TYPE.rms2:
      return "RMS2"
    case HDR_SERVICES_TYPE.rmms:
      return "RMMS"
    case HDR_SERVICES_TYPE.tilt:
      return "TILT"
    case HDR_SERVICES_TYPE.fft:
      return "FFT"
    case HDR_SERVICES_TYPE.accRaw:
      return "ACCRAW"
    case HDR_SERVICES_TYPE.gps:
      return "GPS"
    case HDR_SERVICES_TYPE._4t20:
      return "4T20"
    case HDR_SERVICES_TYPE.ntc:
      return "NTC"
    case HDR_SERVICES_TYPE.pot:
      return "POT"
  }
}

import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: start;

  padding: 0 0 0 16px;
  width: fit-content;
`

export const TooltipWrapper = styled.div`
  position: relative;
`

export const Tooltip = styled.div`
  position: absolute;
  top: 22px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { ChartType } from "../../../components/Chart/entities/ChartData"

const TYPE_ONE_SERVICE = [
  HDR_SERVICES_TYPE.health,
  HDR_SERVICES_TYPE.temp,
  HDR_SERVICES_TYPE.tempMMM,
  HDR_SERVICES_TYPE.rms2,
  HDR_SERVICES_TYPE.rmms,
  HDR_SERVICES_TYPE.tilt,
  HDR_SERVICES_TYPE.gps,
  HDR_SERVICES_TYPE._4t20,
  HDR_SERVICES_TYPE.ntc,
  HDR_SERVICES_TYPE.pot,
]
const TYPE_TWO_SERVICES = [HDR_SERVICES_TYPE.fft]
const TYPE_THREE_SERVICES = [HDR_SERVICES_TYPE.accRaw]

export const filterServices = (
  chartType: ChartType,
  services: { type: HDR_SERVICES_TYPE; axes: string[] }[]
) => {
  return services.filter((service) => {
    if (chartType === ChartType.ONE)
      return TYPE_ONE_SERVICE.includes(service.type)
    if (chartType === ChartType.TWO)
      return TYPE_TWO_SERVICES.includes(service.type)
    if (chartType === ChartType.THREE)
      return TYPE_THREE_SERVICES.includes(service.type)

    return false
  })
}

import { FormEvent, useMemo, useState } from "react"
import { Text } from "../../../../../../components/ui/Text"
import { NameContainer, Input, PathContainer, Form } from "./styles"
import { PencilSimple } from "phosphor-react"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"

interface ServiceNameProps {
  fullPath: string
}

export const ServiceName = ({ fullPath }: ServiceNameProps) => {
  const [edit, setEdit] = useState(false)

  const handleEditIcon = () => {
    setEdit((prev) => !prev)
  }

  const handleEdit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setEdit(false)
  }

  const path = useMemo(() => {
    const pathArray = fullPath.split("/")

    if (!pathArray.pop()) return ""

    return pathArray.join("/").substring(1)
  }, [fullPath])

  const name = useMemo(() => fullPath.split("/").pop() || "", [fullPath])

  return (
    <NameContainer>
      <Form onSubmit={handleEdit}>
        {!edit && (
          <PathContainer>
            <Text fontWeight='medium' color='gray.500'>
              {path}/
            </Text>

            <Text fontWeight='medium' color='gray.800'>
              {name}
            </Text>

            <FlexContainer padding='0 0 0 4px'>
              <PencilSimple size={18} onClick={handleEditIcon} />
            </FlexContainer>
          </PathContainer>
        )}

        {edit && <Input value={path} onChange={() => {}} />}
      </Form>
    </NameContainer>
  )
}

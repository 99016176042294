import styled from "styled-components"
import { media } from "../../../utils/media-queries"

export const TreeAssetsHeader = styled.div`
  padding: 12px 0px 0px 16px;
`

export const InputContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  width: 100%;
  gap: 5px;
`

export const TreeAssetsContainer = styled.div`
  overflow-y: scroll;

  flex: 1 1 auto;

  width: 100%;

  max-height: 200px;

  @media ${media.extraLarge} {
    min-width: 274px;
    max-height: none;
  }

  border-top: 1px solid ${(props) => props.theme.palette.gray[300]};
  margin-top: 12px;
`

import { useState } from "react"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"
import { Checkbox } from "../../../../../../components/ui/Checkbox"
import { TooltipWrapper, Tooltip, Container } from "./styles"
import { Text } from "../../../../../../components/ui/Text"
import { CaretDown, CaretUp } from "phosphor-react"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { getHDRServiceAcronym } from "../../../../../../utils/hdr_services_name"

interface ServiceItemProps {
  type: HDR_SERVICES_TYPE
  axes: string[]
}

export const ServiceItem = ({ type, axes }: ServiceItemProps) => {
  const [showAxes, setShowAxes] = useState(false)

  return (
    <Container>
      <Checkbox />

      {showAxes && (
        <TooltipWrapper>
          <Tooltip>
            <FlexContainer gap={4} align='start'>
              {axes.map((axle) => (
                <FlexContainer key={axle} direction='row' gap={8}>
                  <Checkbox />
                  <Text>{axle}</Text>
                </FlexContainer>
              ))}
            </FlexContainer>
          </Tooltip>
        </TooltipWrapper>
      )}

      <FlexContainer
        direction='row'
        justify='space-between'
        padding='0 0 0 8px'
        gap={8}
        onClick={() => setShowAxes((state) => !state)}
      >
        <Text>{getHDRServiceAcronym(type)}</Text>

        {showAxes && axes.length !== 0 && <CaretUp size={12} />}
        {!showAxes && axes.length !== 0 && <CaretDown size={12} />}
      </FlexContainer>
    </Container>
  )
}

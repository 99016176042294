import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const TreeContainer = styled.div`
  width: 100%;

  @media ${media.extraLarge} {
    width: 300px;
    height: 100%;
  }

  border: 1px solid ${({ theme }) => theme.palette.gray[400]};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  height: 100%;

  @media ${media.extraLarge} {
    flex-direction: row;
    height: 500px;
  }
`

import { HTMLAttributes, ReactNode } from "react"
import { TreeItemIndex, TreeItemRenderContext } from "react-complex-tree"
import { AssetIcon } from "../AssetIcon"
import {
  AddAssetButton,
  ContainerWithChildren,
  Content,
  ExpandAssetButton,
  Wrapper,
  ItemContainer,
} from "./styles"
import { BsPlus } from "react-icons/bs"
import { CaretDown } from "phosphor-react"

export interface TreeItemRendererProps
  extends Omit<HTMLAttributes<HTMLLIElement>, "title"> {
  itemIdx: TreeItemIndex
  context: TreeItemRenderContext<"expandedItems">
  depth: number
  children?: ReactNode
  type: number
  childrenAsset: TreeItemIndex[] | undefined
  label: string
  handleSelectAsset: (id: number) => void
  canAddAsset: boolean
}

export const TreeItemRenderer = ({
  itemIdx,
  context,
  children,
  childrenAsset,
  type,
  label,
  depth,
  handleSelectAsset,
  canAddAsset,
}: TreeItemRendererProps) => {
  const hasChildren = !!(childrenAsset && childrenAsset.length)

  return (
    <Wrapper {...context.itemContainerWithChildrenProps}>
      <ItemContainer
        {...context.itemContainerWithoutChildrenProps}
        $selected={context.isSelected ?? false}
        onClick={(e) => context.selectItem()}
      >
        <Content
          {...context.interactiveElementProps}
          $depth={depth}
          onClick={() => {}}
        >
          {hasChildren && context.isExpanded !== undefined && (
            <ExpandAssetButton $expanded={context.isExpanded}>
              <CaretDown
                size={16}
                onClick={(e) => {
                  if (context.isExpanded) {
                    context.collapseItem()
                  } else {
                    context.expandItem()
                  }

                  e.stopPropagation()
                }}
              />
            </ExpandAssetButton>
          )}

          <AssetIcon type={type} />
          {label}
        </Content>

        {canAddAsset && (
          <AddAssetButton>
            <BsPlus
              size={20}
              onClick={() => handleSelectAsset(Number(itemIdx))}
            />
          </AddAssetButton>
        )}
      </ItemContainer>
      {context.isExpanded && (
        <ContainerWithChildren>{children}</ContainerWithChildren>
      )}
    </Wrapper>
  )
}

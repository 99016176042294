import { useState } from "react"
import { ModalHedro } from "../../../../components/ModalHedro"
import {
  AssetsTree,
  RenderItemProps,
} from "../../../../components/ui/AssetsTree"
import { FlexContainer } from "../../../../components/ui/FlexContainer"
import { Asset, AssetsTable } from "./components/AssetsTable"
import { TreeItemRenderer } from "./components/TreeItemRenderer"
import { Content, TreeContainer } from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ConfigureAssetData } from "../../../../store/api/mock/entities"
import { useFetchChartAssetsQuery } from "../../../../store/store"
import { Text } from "../../../../components/ui/Text"
import { filterServices } from "../../utils/filterServices"
import { ChartType } from "../../../../components/Chart/entities/ChartData"

interface ModalConfigureAssetsProps {
  onClose: () => void
  onBack: () => void
  onAction: () => void
  chartType: ChartType
}

export const ModalConfigureAssets = ({
  onClose,
  onBack,
  onAction,
  chartType,
}: ModalConfigureAssetsProps) => {
  const {
    dashboard: { configureAssets },
  } = useAppTranslate()

  const { data, isError, isLoading } = useFetchChartAssetsQuery()

  const [assets, setAssets] = useState<Asset[]>([])

  const handleSelectAsset = (id: number) => {
    if (!data) return

    setAssets((prev) => [
      ...prev,
      {
        id: data[id].data.id,
        name: data[id].data.name,
        services: data[id].data.services,
        path: data[id].data.path,
      },
    ])
  }

  const handleRemoveAsset = (id: number) => {
    setAssets((prev) => prev.filter((asset) => asset.id !== id))
  }

  const renderItem = ({
    item,
    ...props
  }: RenderItemProps<ConfigureAssetData>) => {
    const filteredServices = filterServices(chartType, item.data.services)

    const alreadyAdded = assets.find((asset) => asset.id === item.data.id)

    const canAddAsset = filteredServices.length > 0 && !alreadyAdded

    return (
      <TreeItemRenderer
        {...props}
        childrenAsset={item.children}
        itemIdx={item.index}
        type={item.data.typeId}
        label={item.data.name}
        handleSelectAsset={handleSelectAsset}
        canAddAsset={canAddAsset}
      />
    )
  }

  const maxServices = assets.reduce(
    (acc, curr) => Math.max(acc, curr.services.length),
    0
  )

  const isDisabled = isLoading || isError || assets.length === 0

  const size = isError ? "md" : "xl"

  return (
    <ModalHedro
      onClose={onClose}
      onBack={onBack}
      onAction={onAction}
      title={configureAssets.title}
      subtitle={configureAssets.subtitle}
      size={size}
      actionBar={configureAssets.submit}
      disableAction={isDisabled}
    >
      {isError && (
        <FlexContainer>
          <Text>{configureAssets.error}</Text>
        </FlexContainer>
      )}

      {!isError && (
        <Content>
          {data && (
            <TreeContainer>
              <AssetsTree renderItem={renderItem} listAssets={data} />
            </TreeContainer>
          )}

          <AssetsTable
            assets={assets}
            maxServices={maxServices}
            chartType={chartType}
            handleRemoveAsset={handleRemoveAsset}
          />
        </Content>
      )}
    </ModalHedro>
  )
}

import { ReactNode, useMemo, useRef, useState } from "react"
import {
  Tree,
  TreeItem,
  TreeItemRenderContext,
  TreeRef,
  UncontrolledTreeEnvironment,
} from "react-complex-tree"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { InputContainer, TreeAssetsContainer, TreeAssetsHeader } from "./styles"
import { Text } from "../Text"
import { Divider } from "../Divider"
import { Input } from "../Input"
import { MagnifyingGlass } from "phosphor-react"
import { CustomDataProvider } from "./CustomDataProvider"
import { TreeContentController } from "./treeContentController"
import { AssetTreeData, BaseAssetData } from "./entities"
import { FlexContainer } from "../FlexContainer"

export interface RenderItemProps<T> {
  item: TreeItem<T>
  depth: number
  children: ReactNode | null
  title: ReactNode
  arrow: ReactNode
  context: TreeItemRenderContext<"expandedItems">
}

interface AssetsTreeProps<T extends BaseAssetData> {
  renderItem: (props: RenderItemProps<T>) => JSX.Element
  listAssets: AssetTreeData<T>
}

export const AssetsTree = <T extends BaseAssetData>({
  renderItem,
  listAssets,
}: AssetsTreeProps<T>) => {
  const dataProvider = useMemo(() => {
    if (!listAssets) {
      return new CustomDataProvider<T>({})
    } else {
      return new CustomDataProvider<T>({ ...listAssets })
    }
  }, [listAssets])

  const tree = useRef<TreeRef>(null)

  const [search, setSearch] = useState("")

  const { find } = TreeContentController(dataProvider, search, tree)
  const { assets } = useAppTranslate()

  return (
    <FlexContainer fullHeight fullWidth align='start'>
      <TreeAssetsHeader>
        <Text fontWeight='semibold' color='gray.800'>
          {assets.tree.title}
        </Text>
      </TreeAssetsHeader>

      <Divider />

      <InputContainer>
        <Input
          placeholder={assets.tree.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          icon={<MagnifyingGlass size={20} weight='bold' />}
          onClickIcon={find}
        />
      </InputContainer>

      <TreeAssetsContainer>
        <UncontrolledTreeEnvironment
          canDragAndDrop
          canDropOnFolder
          canReorderItems
          dataProvider={dataProvider}
          getItemTitle={(item) => item.data.name}
          viewState={{ "tree-1": { expandedItems: ["root"] } }}
          renderDepthOffset={20}
          renderItemTitle={({ title }) => <span>{title}</span>}
          renderItem={renderItem}
          renderTreeContainer={({ children, containerProps }) => (
            <div {...containerProps}>{children}</div>
          )}
          renderItemsContainer={({ children, containerProps }) => (
            <ul {...containerProps}>{children}</ul>
          )}
        >
          <Tree
            treeId='tree-1'
            rootItem='master'
            treeLabel='Tree Example'
            ref={tree}
          />
        </UncontrolledTreeEnvironment>
      </TreeAssetsContainer>
    </FlexContainer>
  )
}

import { createContext, ReactNode, useRef, useState } from "react"
import * as z from "zod"

export interface CreateChartContextDTO {
  isOpenConfigureChart: boolean
  isOpenConfigureSensors: boolean
  isOpenConfigureAssets: boolean
  handleOpenConfigureChart: () => void
  handleCloseAll: () => void
  handleChangeChart: (data: ChartSchema) => void
  handleBackConfigureChart: () => void
  handleCreateChart: () => void
  chart?: ChartSchema
}

export const CreateChartContext = createContext<CreateChartContextDTO | null>(
  null
)

export const CreateChartContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isOpenConfigureChart, setIsOpenConfigureChart] = useState(false)
  const [isOpenConfigureSensors, setIsOpenConfigureSensors] = useState(false)
  const [isOpenConfigureAssets, setIsOpenConfigureAssets] = useState(false)

  const chart = useRef<ChartSchema | undefined>()

  const handleOpenConfigureChart = () => {
    setIsOpenConfigureChart(true)
  }

  const handleCloseAll = () => {
    setIsOpenConfigureChart(false)
    setIsOpenConfigureSensors(false)
    setIsOpenConfigureAssets(false)

    chart.current = undefined
  }

  const handleBackConfigureChart = () => {
    setIsOpenConfigureSensors(false)
    setIsOpenConfigureAssets(false)

    setIsOpenConfigureChart(true)
  }

  const handleChangeChart = (data: ChartSchema) => {
    chart.current = data

    setIsOpenConfigureChart(false)

    switch (data.orientedBy) {
      case "sensor":
        setIsOpenConfigureSensors(true)
        break
      case "asset":
        setIsOpenConfigureAssets(true)
        break
    }
  }

  const handleCreateChart = () => {
    chart.current = undefined

    setIsOpenConfigureSensors(false)
  }

  const defaultContext = {
    isOpenConfigureChart,
    isOpenConfigureSensors,
    isOpenConfigureAssets,
    handleOpenConfigureChart,
    handleCloseAll,
    handleBackConfigureChart,
    handleChangeChart,
    handleCreateChart,
    chart: chart.current,
  }

  return (
    <CreateChartContext.Provider value={defaultContext}>
      {children}
    </CreateChartContext.Provider>
  )
}

export const enum SCHEMA_ERRORS {
  REQUIRED = "REQUIRED",
}

export const chartSchema = z
  .object({
    title: z.string().min(1, SCHEMA_ERRORS.REQUIRED),
    type: z.number().min(1, SCHEMA_ERRORS.REQUIRED),
    period: z.string(),
    orientedBy: z.enum(["sensor", "asset"]),
  })
  .superRefine(({ type, period }, ctx) => {
    if (type === 1) {
      if (!period) {
        ctx.addIssue({
          message: SCHEMA_ERRORS.REQUIRED,
          code: "too_small",
          path: ["period"],
          minimum: 1,
          type: "number",
          inclusive: true,
        })

        return false
      }
    }
  })

export type ChartSchema = z.infer<typeof chartSchema>

import { useContext } from "react"
import {
  ChartSchema,
  CreateChartContext,
  CreateChartContextDTO,
} from "../../context/CreateChartContext"
import { ModalConfigureChart } from "../ModalConfigureChart"
import { ModalConfigureSensors } from "../ModalConfigureSensors"
import { DashboardContext } from "../../context/DashboardContext"
import { DashboardContextDTO } from "../../entities/DashboardContextDTO"
import { createsObject } from "./utils"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { postAnalytics } from "../../../../store/features/dashboard/dashboard.api"
import { CheckboxState } from "../../../../components/Modal/components/SensorAndServices/entities/CheckboxServiceStateDTO"
import { ModalConfigureAssets } from "../ModalConfigureAssets"

export const ModalWrapper = () => {
  const {
    chart,
    isOpenConfigureChart,
    isOpenConfigureSensors,
    isOpenConfigureAssets,
    handleCloseAll,
    handleBackConfigureChart,
    handleChangeChart,
    handleCreateChart,
  } = useContext(CreateChartContext) as CreateChartContextDTO

  const { analyticToPlot, sensorAndServices, setCheckboxState } = useContext(
    DashboardContext
  ) as DashboardContextDTO

  const dispatch = useAppDispatch()

  const dashboards = useAppSelector((state) => state.persistedReducer.dashboard)

  const handleSaveChartData = (data: ChartSchema) => {
    if (!dashboards.openDashboard) return

    analyticToPlot.current = {
      tag: data.title,
      type: data.type,
      period: data.type === 1 ? Number(data.period) : 0,
      dashboardId: dashboards.openDashboard.id,
      sensors: {},
    }
  }

  const handleCreateAnalytic = (): void => {
    if (!analyticToPlot.current) {
      console.error("No data to create analytic!")
      return
    }

    analyticToPlot.current = {
      ...analyticToPlot.current,
      sensors: createsObject(sensorAndServices.current),
    }

    dispatch(postAnalytics(analyticToPlot.current)).then((e) => {
      if (e.meta.requestStatus === "fulfilled") {
        analyticToPlot.current = null
        sensorAndServices.current = []

        handleCreateChart()

        setCheckboxState({} as CheckboxState)
      }
    })
  }

  const isDisabled = sensorAndServices.current.length === 0

  return (
    <>
      {isOpenConfigureChart && (
        <ModalConfigureChart
          onClose={handleCloseAll}
          handleSubmitData={(data) => {
            handleChangeChart(data)
            handleSaveChartData(data)
          }}
          defaultValues={chart}
        />
      )}

      {isOpenConfigureSensors && (
        <ModalConfigureSensors
          onClose={handleCloseAll}
          onBack={handleBackConfigureChart}
          onAction={handleCreateAnalytic}
          isDisabled={isDisabled}
        />
      )}

      {isOpenConfigureAssets && chart && (
        <ModalConfigureAssets
          onClose={handleCloseAll}
          onBack={handleBackConfigureChart}
          onAction={() => {}}
          chartType={chart.type}
        />
      )}
    </>
  )
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { AssetTreeData } from "../../../components/ui/AssetsTree/entities"
import { ConfigureAssetData } from "./entities"

export const mockApi = createApi({
  reducerPath: "mockApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3005",
  }),
  endpoints: (builder) => ({
    fetchChartAssets: builder.query<AssetTreeData<ConfigureAssetData>, void>({
      query: (_) => "/assets-structure-chart",
    }),
  }),
})

export const { useFetchChartAssetsQuery } = mockApi
